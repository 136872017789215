import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Shortner from "./components/Shortner";
import { Helmet, HelmetProvider } from "react-helmet-async";

const helmetContext = {};

function App() {
    return (
        <div>
            <HelmetProvider context={helmetContext}>
                <Helmet>
                    <title>Url Shortener</title>
                    <meta name="description" content="Fastest Url Shortener" />
                    <meta
                        name="keywords"
                        content="url, short, longurl, urltrim, urlshortener, longurltoshorturl, makeurlsmall"
                    />
                </Helmet>
                <Shortner />
            </HelmetProvider>
        </div>
    );
}

export default App;
